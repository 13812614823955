import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

class Flickr extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO title="Flickr" />
        <h1>Flickr</h1>
        <p>
          My Flickr Photstream.{" "}
          <a
            href="https://www.flickr.com/photos/serith"
            target="_blank"
            rel="noreferrer"
          >
            Serith on Flickr
          </a>
        </p>
      </Layout>
    )
  }
}

export default Flickr

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
